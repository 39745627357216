import {createApp} from 'vue';
import Main from './components/Main.vue';
import {createWeStore} from './store';
import ew from '../common/mixins/ew';

class App{
	/**
	 * @param {HTMLElement} root_node
	 */
	constructor(root_node){
		this.vue = createApp(Main);
		this.vue.use(createWeStore());
		this.vue.mixin(ew);
		this.vue.mount(root_node);
	}
}

let application = null;

/**
 * @param {HTMLElement} root_node
 * @return {App}
 */
function create_app(root_node){
	if(application){
		application.vue.unmount();
		application.vue.application = null;
	}
	application = new App(root_node);
	return application;
}

export {
	create_app,
};