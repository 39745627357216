import {createStore} from 'vuex';

function createProdStore(){
	return createStore({
		modules: {},
		state(){
			return {
				debug: process.env.NODE_ENV !== 'production',
				mainComponent: 'JobSelection',
				mainComponentParams: {},
			};
		},
		mutations: {
			change_route(state, data){
				state.mainComponentParams = data.params;
				state.mainComponent = data.component;
			},
		},
		actions: {},
	});
}

export {
	createProdStore,
};