<template>
	<section class="we_frontend">
		<component :is="$store.state.mainComponent" v-bind="$store.state.mainComponentParams"/>
	</section>
</template>

<script>
import {defineAsyncComponent} from 'vue';

export default {
	name: "Main",
	components: {
		SelectWe: defineAsyncComponent(() => import('./SelectWe.vue')),
		WeOverview: defineAsyncComponent(() => import('./WeOverview.vue')),
		RecordByLtId: defineAsyncComponent(() => import('./RecordByLtId.vue')),
		RecordByScan: defineAsyncComponent(() => import('./RecordByScan.vue')),
		RecordByQuantityInput: defineAsyncComponent(() => import('./RecordByQuantityInput.vue')),
		RecordByManualInput: defineAsyncComponent(() => import('./RecordByManualInput.vue')),
		Trash: defineAsyncComponent(() => import('./Trash.vue')),
		AddItem: defineAsyncComponent(() => import('./AddItem.vue')),
		Cancellation: defineAsyncComponent(() => import('./Cancellation.vue')),
		BackorderOverview: defineAsyncComponent(() => import('./BackorderOverview.vue')),
	},
	data(){
		return {};
	},
	beforeUnmount(){
		try{
			onScan.detachFrom(document);
		} catch(e){
			//noop
		}
	}
};
</script>

<style scoped>

</style>